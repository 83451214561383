import React, { useRef, useState, useEffect } from "react"
import tw, { styled, css } from "twin.macro"
import { navigate } from "gatsby"
import { connect, useSelector } from "react-redux"
import firebase from "gatsby-plugin-firebase"
import produce from "immer"
import { get, includes, isNull, isNil } from "lodash"
import { useForm, Controller } from "react-hook-form"
import { toast } from "react-toastify"
import { track } from "services/analytics"
import { getPlan } from "auth/services"
import moment from "moment"

import Page from "general/components/Page"
import ApplistInfoGuide from "assets/images/applisting_info.jpg"
import Card from "general/components/Card"
import TextField from "general/components/TextField"
import TagField from "general/components/TagField"
import Select from "general/components/Select"
import FormLayout from "general/components/FormLayout"
import Button from "general/components/Button"
import ImageUploader from "general/components/ImageUploader"
import PageActions from "listing/components/PageActions"
import VerifyLinkButton from "form/components/VerifyLinkButton"

import { openDialog } from "dialog/redux"

import NoImageLaunchscreen from "assets/images/no-image-launchscreen.png"

const CATEGORIES = [
  { value: "Book", label: "Book" },
  { value: "Business", label: "Business" },
  { value: "Education", label: "Education" },
  { value: "Entertainment", label: "Entertainment" },
  { value: "Finance", label: "Finance" },
  { value: "Food_Drink", label: "Food & Drink" },
  {
    value: "Healthcare_Fitness",
    label: "Healthcare & Fitness",
  },
  { value: "Lifestyle", label: "Lifestyle" },
  { value: "Newsstand", label: "Magazines & Newspapers" },
  { value: "Medical", label: "Medical" },
  { value: "Music", label: "Music" },
  { value: "Navigation", label: "Navigation" },
  { value: "News", label: "News" },
  { value: "Photography", label: "Photo & Video" },
  { value: "Productivity", label: "Productivity" },
  { value: "Reference", label: "Reference" },
  { value: "Shopping", label: "Shopping" },
  {
    value: "SocialNetworking",
    label: "Social Networking",
  },
  { value: "Sports", label: "Sports" },
  { value: "Travel", label: "Travel" },
  { value: "Utilities", label: "Utilities" },
  { value: "Weather", label: "Weather" },
]

const TAGS_LIMIT = 100

function ListingApplicationPage(props) {
  const { setFormValid, setFormDirty, openDialog } = props
  const profile = useSelector(state => state.firebase.profile)
  const shopify_url = profile.shopify_url
  const payment = profile.payment
  const paid = get(payment, "paid", false)
  const plan = get(payment, "plan", undefined)
  const applisting = useSelector(
    ({ firestore: { data } }) =>
      data[shopify_url] && data[shopify_url].applisting
  )
  const trialExpiredAt = get(profile, "payment.trial_expired_at", undefined)
  const isTrialExpired = trialExpiredAt
    ? moment(trialExpiredAt.toDate()).diff(moment()) <= 0
    : true
  const appIconRef = useRef(null)
  const launchScreenRef = useRef(null)

  const {
    handleSubmit,
    register,
    errors,
    formState,
    reset,
    watch,
    setValue,
    getValues,
    control,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  })
  const { isDirty, isValid, isSubmitted } = formState

  const [saveLoading, setSaveLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState(null)

  useEffect(() => {
    const queryParams = window.location.search
    const urlParams = new URLSearchParams(queryParams)
    const isEdit = urlParams.get(`edit`) || false

    if (!applisting) {
      return
    }

    const { has_android_account, has_ios_account, required } = applisting

    const { platform } = payment

    if (!isEdit) {
      // console.log("REDIRECT", required, get(payment, "paid", false))

      if (required) {
        if (!get(payment, "paid", false)) {
          openDialog({ type: "GROWTH_PLAN" })
        } else {
          if (getPlan(plan) === "starter") {
            if (isNull(platform)) {
              return navigate("/app/listing/developer")
            }
            if (platform === "ios" && isNil(has_ios_account)) {
              return navigate("/app/listing/developer")
            }
            if (platform === "android" && isNil(has_android_account)) {
              return navigate("/app/listing/developer")
            }
            return navigate("/app/listing/status")
          } else {
            //Growth plan부터
            if (isNil(has_android_account) || isNil(has_ios_account)) {
              return navigate("/app/listing/developer")
            }

            return navigate("/app/listing/status")
          }
        }
        // return navigate("/app/listing/developer")
      }

      // if (!isUndefined(has_android_account) || !isUndefined(has_ios_account)) {
      //   navigate("/app/listing/status")
      // }
    }
  }, [])

  useEffect(() => {
    track("VIEW_PAGE_APPINFO")
  }, [])

  useEffect(() => {
    if (isSubmitted) {
      setFormDirty(false)
    }
  }, [isSubmitted])

  useEffect(() => {
    setFormValid(isValid)
  }, [isValid])

  useEffect(() => {
    setFormDirty(isDirty)
  }, [isDirty])

  useEffect(() => {
    let nextTags = ["Shopping"]
    if (applisting) {
      const { keywords } = applisting

      if (keywords) {
        nextTags = keywords.split(",")
      }
    }

    setFormData({
      title: get(applisting, "title", ""),
      title_confirm: get(applisting, "title_confirm", false),
      subtitle: get(applisting, "subtitle", ""),
      app_description: get(applisting, "app_description", ""),
      category1: get(applisting, "category1", "Shopping"),
      category2: get(applisting, "category2", "Lifestyle"),
      app_icon: get(applisting, "app_icon", ""),
      launch_screen: get(applisting, "launch_screen", ""),
      privacy_policy_url: get(applisting, "privacy_policy_url", ""),
      support_url: get(applisting, "support_url", ""),
      marketing_url: get(applisting, "marketing_url", ""),
      tags: nextTags,
    })
  }, [applisting])

  const handleSave = () => {
    console.log("click")
    if (!paid) {
      if (isTrialExpired) {
        return openDialog({ type: "TRIAL_EXPIRED" })
      }
    }

    setSaveLoading(true)
    const user = firebase.auth().currentUser
    const DB = firebase.firestore()
    const batch = DB.batch()
    const ref = DB.collection(shopify_url).doc("applisting")
    const userRef = DB.collection("user_list").doc(user.uid)
    const values = getValues()

    batch.set(
      ref,
      {
        ...values,
        required: isValid,
        updated_date: firebase.firestore.FieldValue.serverTimestamp(),
      },
      { merge: true }
    )
    batch.set(
      userRef,
      {
        settings: {
          app_name: values.title,
          app_icon: values.app_icon,
        },
      },
      { merge: true }
    )

    /*TODO : Rollback 대비용 (app_name, icon, launchscreen) - 안정후에 지워야함.*/
    const rollBackIconRef = DB.collection(shopify_url)
      .doc("applisting")
      .collection("design")
      .doc("app_icon")
    const rollBackLaunchScreenRef = DB.collection(shopify_url)
      .doc("applisting")
      .collection("design")
      .doc("launch_screen")
    const rollbackAppNameRef = DB.collection(shopify_url).doc("settings")

    batch.set(
      rollBackIconRef,
      {
        image_url: values.app_icon,
      },
      { merge: true }
    )
    batch.set(
      rollBackLaunchScreenRef,
      {
        image_url: values.launch_screen,
      },
      { merge: true }
    )
    batch.set(
      rollbackAppNameRef,
      {
        app_name: values.title,
      },
      { merge: true }
    )

    batch
      .commit()
      .then(() => {
        window.Intercom("update", {
          test_applisting_requred: isValid,
        })

        setSaveLoading(false)
        toast("Save Success")
        reset(values)
      })
      .catch(error => {
        setSaveLoading(false)
        toast(error)
        window.Sentry.captureException(error)
      })
  }

  const onSubmit = values => {
    setLoading(true)

    const user = firebase.auth().currentUser
    const DB = firebase.firestore()
    const batch = DB.batch()
    const applistingRef = DB.collection(shopify_url).doc("applisting")
    const userRef = DB.collection("user_list").doc(user.uid)

    batch.set(
      applistingRef,
      {
        ...values,
        required: true,
        updated_date: firebase.firestore.FieldValue.serverTimestamp(),
      },
      { merge: true }
    )

    batch.set(
      userRef,
      {
        settings: {
          app_name: values.title,
          app_icon: values.app_icon,
        },
      },
      { merge: true }
    )

    /*TODO : Rollback 대비용 (app_name, icon, launchscreen) - 안정후에 지워야함.*/
    const rollBackIconRef = DB.collection(shopify_url)
      .doc("applisting")
      .collection("design")
      .doc("app_icon")
    const rollBackLaunchScreenRef = DB.collection(shopify_url)
      .doc("applisting")
      .collection("design")
      .doc("launch_screen")
    const rollBackAppnameRef = DB.collection(shopify_url).doc("settings")

    batch.set(
      rollBackIconRef,
      {
        image_url: values.app_icon,
      },
      { merge: true }
    )
    batch.set(
      rollBackLaunchScreenRef,
      {
        image_url: values.launch_screen,
      },
      { merge: true }
    )
    batch.set(
      rollBackAppnameRef,
      {
        app_name: values.title,
      },
      { merge: true }
    )

    batch
      .commit()
      .then(() => {
        setLoading(false)

        window.Intercom("update", {
          test_applisting_requred: true,
        })

        if (get(payment, "paid", false)) {
          return navigate("/app/listing/developer")
        }

        if (!isTrialExpired) {
          return openDialog({
            type: "GROWTH_PLAN",
            modalProps: {
              title: "Wow! Almost Done!",
            },
          })
        }

        return openDialog({
          type: "GROWTH_PLAN",
        })
      })
      .catch(error => {
        setLoading(false)
        toast(error)
        window.Sentry.captureException(error)
      })
  }

  const handlePrimary = () => {
    if (!isValid) {
      return toast("Please fill out the required fields and try again.")
    }
    handleSubmit(onSubmit)()
  }

  const getTagsLength = () => {
    let length = 0

    formData.tags.forEach(tag => {
      length = length + tag.length + 1
    })

    return length
  }

  const handleDeleteTag = index => {
    if (locked) {
      return false
    }
    const nextTags = formData.tags.slice(index, 1)

    setValue("keywords", nextTags, { shouldDirty: true, shouldValidate: true })

    setFormData(
      produce(draft => {
        draft.tags.splice(index, 1)
      })
    )
  }

  const handleAdditionTag = tag => {
    if (locked) {
      return false
    }
    const tagsLength = getTagsLength()
    const tagLength = tag.length

    if (TAGS_LIMIT < tagsLength + tagLength) {
      toast(`You can add tag up to ${TAGS_LIMIT}`)

      return false
    }

    const nextTags = formData.tags.concat(tag)

    setValue("keywords", nextTags, { shouldDirty: true, shouldValidate: true })

    setFormData(
      produce(draft => {
        draft.tags.push(tag)
      })
    )
  }

  const handleChangeImage = (type, imageUrl) => {
    setValue(type, imageUrl, { shouldDirty: true, shouldValidate: true })

    if (type === "app_icon") {
      setFormData(
        produce(draft => {
          draft.app_icon = imageUrl
        })
      )
    }

    if (type === "launch_screen") {
      setFormData(
        produce(draft => {
          draft.launch_screen = imageUrl
        })
      )
    }
  }

  if (isNull(formData)) {
    return null
  }

  const locked = get(applisting, "locked", false)

  return (
    <Page title="App Store Listing">
      <div className="flex flex-row flex-wrap -mx-3">
        <div className="px-3 lg:w-8/12">
          <form>
            <div className="grid grid-cols-1 gap-4">
              <Card title="Add App Information">
                <p className="mb-4">
                  Information about your app that will be displayed in the App
                  Store listing.
                </p>
                <FormLayout>
                  <TextField
                    disabled={locked}
                    label={
                      <span>
                        App title
                        <span className="text-primary-500 text-sm ml-1">
                          (Required)
                        </span>
                      </span>
                    }
                    maxLength={30}
                    name="title"
                    error={get(errors, "title.message")}
                    defaultValue={formData.title}
                    ref={register({ required: "A title is required" })}
                    placeholder={"Your app name"}
                    helpText="Displays under your app icon on the mobile phone's home screen."
                  />
                  <div className="bg-red-100 py-4 px-4">
                    <span className="text-primary-500 block">
                      <p className="mb-1">
                        App title is final so we cannot change the app title
                        later. please double check it.
                      </p>
                    </span>
                    <label className="flex items-center text-gray-800">
                      <input
                        disabled={locked}
                        type="checkbox"
                        className="form-checkbox"
                        name="title_confirm"
                        defaultChecked={formData.title_confirm}
                        ref={register({
                          required: "Please check your app title",
                        })}
                        error={get(errors, "title_confirm.message")}
                      />
                      <span className="ml-2">I checked. This is correct.</span>
                    </label>
                  </div>

                  <TextField
                    disabled={locked}
                    maxLength={30}
                    error={get(errors, "subtitle.message")}
                    ref={register({ required: "A subtitle is required" })}
                    defaultValue={formData.subtitle}
                    name="subtitle"
                    label={
                      <span>
                        App subtitle
                        <span className="text-primary-500 text-sm ml-1">
                          (Required)
                        </span>
                      </span>
                    }
                    placeholder={"Your app subtitle"}
                  />
                  <Controller
                    name="app_description"
                    control={control}
                    defaultValue={formData.app_description}
                    render={({ onChange, value }) => {
                      return (
                        <TextField
                          name="app_description"
                          disabled={locked}
                          ref={register({
                            required: "A description is required",
                          })}
                          error={get(errors, "app_description.message")}
                          onChange={event => {
                            const text = event.target.value.replace(
                              /([\uE000-\uF8FF]|\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDDFF])/g,
                              ""
                            )

                            onChange(text)
                          }}
                          value={value}
                          label={
                            <span>
                              App store description
                              <span className="text-primary-500 text-sm ml-1">
                                (Required)
                              </span>
                            </span>
                          }
                          multiline={3}
                          maxLength={4000}
                          placeholder="Here is what out app does and why to download it..."
                          helpText={
                            <div>
                              <span className="block">{`A description of your app, detailing features and functionality. (up to 4000 characters)`}</span>
                              <span className="block">{`* emoji is not allowed on description.`}</span>
                            </div>
                          }
                        />
                      )
                    }}
                  />
                </FormLayout>
              </Card>
              <Card
                title={
                  <div className="flex flex-row items-center">
                    <span>Keywords</span>
                    <span className="text-sm font-normal ml-2 leading-none">
                      <span className="text-primary-500 ">
                        {getTagsLength()}
                      </span>
                      {`/${TAGS_LIMIT}`}
                    </span>
                  </div>
                }
              >
                <TagField
                  disabled={locked}
                  tags={formData.tags}
                  onAddition={handleAdditionTag}
                  onDelete={handleDeleteTag}
                />
                <input
                  ref={register}
                  type="hidden"
                  name="keywords"
                  value={formData.tags}
                />
              </Card>

              <Card title="App store categories">
                <div className="flex flex-row flex-wrap -mx-2">
                  <div className="flex-1 px-2">
                    <Select
                      disabled={locked}
                      options={CATEGORIES}
                      name="category1"
                      value={formData.category1}
                      ref={register}
                      onChange={event =>
                        setValue("category1", event.target.value)
                      }
                    />
                  </div>
                  <div className="flex-1 px-2">
                    <Select
                      disabled={locked}
                      options={CATEGORIES}
                      name="category2"
                      value={formData.category2}
                      ref={register}
                      onChange={event =>
                        setValue("category2", event.target.value)
                      }
                    />
                  </div>
                </div>
              </Card>
              <Card title="Media">
                <FormLayout>
                  <div>
                    <span
                      css={tw`block text-base text-gray-900 font-medium mb-2`}
                    >
                      App Icon
                      <span className="text-primary-500 text-sm ml-1">
                        (Required)
                      </span>
                    </span>
                    <div className="flex flex-row flex-wrap">
                      <div
                        className="bg-gray-100 mr-4 border border-gray-300"
                        css={css`
                          width: 140px;
                        `}
                      >
                        <input
                          type="hidden"
                          name="app_icon"
                          defaultValue={get(formData, "app_icon", "")}
                          ref={register({ required: true })}
                        />
                        <ImageUploader
                          disabled={locked}
                          ref={appIconRef}
                          source={get(formData, "app_icon", "")}
                          onAction={url => handleChangeImage("app_icon", url)}
                          fixedRatio={1}
                          customValidator={data => {
                            const { width, height } = data
                            const ratio = (
                              (height * 1.0) /
                              (width * 1.0)
                            ).toFixed(3)

                            if (width < 500 || height < 500) {
                              toast(
                                "Please check your image size. Minimum size is 500 x 500 pixels."
                              )
                              return false
                            }

                            // if (parseFloat(ratio) !== 1) {
                            //   toast("Please upload square size image.")
                            //   return false
                            // }

                            return true
                          }}
                        />
                      </div>
                      <div className="flex-1">
                        <ul className="list-disc ml-4">
                          <li>
                            Add your app icon for launching into App Store.
                          </li>
                          <li>Recommended size of 1,024 x 1,024 pixels.</li>
                          <li>Minimum size is 500 x 500 pixels.</li>
                        </ul>
                        <div className="mt-4">
                          <div className="flex flex-row flex-wrap -mx-1">
                            <div className="px-1">
                              <Button
                                disabled={locked}
                                type="button"
                                size="slim"
                                onClick={() => {
                                  setValue("app_icon", undefined, {
                                    shouldDirty: true,
                                    shouldValidate: true,
                                  })

                                  setFormData(
                                    produce(draft => {
                                      draft.app_icon = ""
                                    })
                                  )
                                }}
                              >
                                Delete
                              </Button>
                            </div>
                            <div className="px-1">
                              <Button
                                disabled={locked}
                                type="button"
                                primary
                                size="slim"
                                onClick={() => appIconRef.current.click()}
                              >
                                Upload
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <span
                      css={tw`block text-base text-gray-900 font-medium mb-2`}
                    >
                      Launch Screen (Optional)
                    </span>
                    <div className="flex flex-row flex-wrap">
                      <div
                        className="bg-gray-100 mr-4  border border-gray-300"
                        css={css`
                          width: 140px;
                        `}
                      >
                        <input
                          type="hidden"
                          name="launch_screen"
                          ref={register}
                          defaultValue={get(formData, "launch_screen", "")}
                        />
                        <ImageUploader
                          disabled={locked}
                          ref={launchScreenRef}
                          emptyImage={NoImageLaunchscreen}
                          source={get(formData, "launch_screen")}
                          onAction={url =>
                            handleChangeImage("launch_screen", url)
                          }
                          fixedRatio={2.164}
                          customValidator={data => {
                            const { width, height } = data

                            if (width !== 1125 || height !== 2436) {
                              toast(
                                "Please check your image size. Launch Screen image size is permitted only 1,125x2,436 pixels."
                              )
                              return false
                            }

                            return true
                          }}
                        />
                      </div>
                      <div className="flex-1">
                        <ul className="list-disc ml-4">
                          <li>
                            Launch Screen is the first loading screen that pops
                            up when you open your store app.
                          </li>
                          <li>
                            Typically, Launch screen is set with the logo in the
                            center of the screen.
                          </li>
                          <li>
                            Launch Screen image size is permitted only
                            1,125x2,436 pixels.
                          </li>
                        </ul>
                        <div className="mt-4">
                          <div className="flex flex-row flex-wrap -mx-1">
                            <div className="px-1">
                              <Button
                                disabled={locked}
                                type="button"
                                size="slim"
                                onClick={() => {
                                  setValue("launch_screen", undefined, {
                                    shouldDirty: true,
                                    shouldValidate: true,
                                  })

                                  setFormData(
                                    produce(draft => {
                                      draft.launch_screen = ""
                                    })
                                  )
                                }}
                              >
                                Delete
                              </Button>
                            </div>
                            <div className="px-1">
                              <Button
                                disabled={locked}
                                type="button"
                                primary
                                size="slim"
                                onClick={() => launchScreenRef.current.click()}
                              >
                                Upload
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </FormLayout>
              </Card>
              <Card title="Add App Information">
                <FormLayout>
                  <TextField
                    label={
                      <span>
                        Privacy policy URL
                        <span className="text-primary-500 text-sm ml-1">
                          (Required)
                        </span>
                      </span>
                    }
                    type="url"
                    error={get(errors, "privacy_policy_url.message")}
                    defaultValue={formData.privacy_policy_url}
                    name="privacy_policy_url"
                    disabled={locked}
                    helpText={
                      <span className="text-primary-500">{`* Please review your privacy policy link and confirm it is correct. Wrong information may block the launch of your app.`}</span>
                    }
                    ref={register({ required: "A privacy policy is required" })}
                    connectedRight={
                      <VerifyLinkButton
                        url={
                          watch("privacy_policy_url") ||
                          formData.privacy_policy_url
                        }
                      />
                    }
                  />
                  <TextField
                    disabled={locked}
                    label={
                      <span>
                        Support URL
                        <span className="text-primary-500 text-sm ml-1">
                          (Required)
                        </span>
                      </span>
                    }
                    type="url"
                    name="support_url"
                    error={get(errors, "support_url.message")}
                    defaultValue={formData.support_url}
                    helpText={
                      <span className="text-primary-500">{`*Enter Contact Us Page or Shopify Store url`}</span>
                    }
                    connectedRight={
                      <VerifyLinkButton
                        url={watch("support_url") || formData.support_url}
                      />
                    }
                    ref={register({ required: "A support url is required" })}
                  />
                  <TextField
                    disabled={locked}
                    label="Marketing URL (Optional)"
                    name="marketing_url"
                    defaultValue={formData.marketing_url}
                    placeholder={"Enter your Shopify Store url"}
                    type="url"
                    ref={register}
                    connectedRight={
                      <VerifyLinkButton
                        url={watch("marketing_url") || formData.marketing_url}
                      />
                    }
                  />
                </FormLayout>
              </Card>
            </div>
          </form>
        </div>
        <div className="px-3 lg:w-4/12">
          <Card>
            <img src={ApplistInfoGuide} alt="" />
          </Card>
        </div>
      </div>
      <PageActions
        actions={[
          {
            disabled: saveLoading || !isDirty,
            loading: saveLoading,
            content: "Save",
            primary: false,
            onAction: () => handleSave(),
          },
          {
            disabled: loading,
            loading: loading,
            primary: true,
            content: "Next",
            onAction: () => handlePrimary(),
          },
        ]}
      />
    </Page>
  )
}

export default connect(undefined, { openDialog })(ListingApplicationPage)
