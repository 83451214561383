import React, { useState, useEffect } from "react"
import { Link, navigate } from "gatsby"
import { useSelector, connect } from "react-redux"
import tw, { css } from "twin.macro"
import produce from "immer"
import { get, map, reduce } from "lodash"
import { useForm } from "react-hook-form"
import { getFirebase } from "react-redux-firebase"
import { toast } from "react-toastify"
import moment from "moment"
import { sendPush, resetPush } from "push/redux"
import ShopifyModalProvider from "shopify/components/ShopifyModalProvider"
import TextField from "general/components/TextField"
import FormLayout from "general/components/FormLayout"
import Button from "general/components/Button"
import Device from "push/components/Device"
import PushReservationModal from "push/components/PushReservationModal"
import ImgProductPlaceholder from "assets/images/push/product-placeholder.png"
import PushSuccess from "assets/images/feedback/push-success.png"
import JcurveLogo from "assets/images/push/logo-jcurve.png"

import LogoSpinner from "general/components/LogoSpinner"
import Overlay from "general/components/Overlay"
import FeedbackModal from "modal/components/FeedbackModal"
import Page from "general/components/Page"
import { getPlan } from "auth/services"

import { GET_PRODUCT_INFO_BY_ID_FOR_PUSH } from "shopify/queries"
import { openDialog } from "dialog/redux"
import { useLazyQuery } from "@apollo/client"

function PushPage(props) {
  const firebase = getFirebase()
  const profile = useSelector(({ firebase }) => firebase.profile)
  const plan = get(profile, "payment.plan", undefined)
  const paid = get(profile, "payment.paid", false)
  // const appName = get(profile, 'settings.app_name', undefined);
  const trialExpiredAt = get(profile, "payment.trial_expired_at", undefined)

  let isTrialExpired = trialExpiredAt
    ? moment(trialExpiredAt.toDate()).diff(moment()) <= 0
    : true

  const { appName, appIcon, isLoading, isEmpty, status, type } = props
  const { sendPush, resetPush, openDialog } = props
  const [feedback, setFeedback] = useState(false)
  const [expended, setExpended] = useState(false)
  const [validateErrors, setValidateErrors] = useState({
    reservation: {},
  })
  const [search, setSearch] = useState({
    isOpen: false,
    meta: {
      resourceType: "products",
    },
  })
  const [reservation, setReservation] = useState({
    isOpen: false,
    meta: {},
  })
  const [data, setData] = useState({
    title: "",
    message: "",
    product: null,
    datetime: new Date(),
  })
  const [
    loadProductImages,
    { loading, error, data: fetchingData },
  ] = useLazyQuery(GET_PRODUCT_INFO_BY_ID_FOR_PUSH, {
    fetchPolicy: "network-only",
  })

  const {
    register,
    handleSubmit,
    watch,
    errors,
    formState,
    getValues,
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      title: "",
      message: "",
    },
  })

  useEffect(() => {
    if (!loading) {
      if (error) {
        window.Sentry.captureException(error)
      }

      const content = get(fetchingData, "nodes.0", undefined)

      if (content) {
        setData(
          produce(draft => {
            draft.product = {
              id: get(content, "id"),
              images: get(content, "images.edges"),
              title: get(content, "title"),
            }
          })
        )
      }
    }
  }, [loading])

  useEffect(() => {
    if (!isLoading && !isEmpty) {
      if (status === "success") {
        setFeedback(true)
        reset({
          title: "",
          message: "",
        })
        setData({
          title: "",
          message: "",
          product: null,
          datetime: new Date(),
        })
        setExpended(false)
      }

      if (status === "failure") {
        toast("Oops. Push is not delivered. Can you try to send again after few minutes later")
        resetPush()
      }
    }
  }, [isLoading, isEmpty, status])

  const handleSendPush = type => {
    const values = getValues()
    const data = {
      title: values.title,
      message: values.message,
      targetId: values.targetId,
      targetType: values.targetType,
    }

    if (
      type === "reservation" &&
      plan &&
      getPlan(plan) === "starter"
    ) {
      return openDialog({ type: "PROMOTE_PUSH" })
    }

    if (
      get(values, "images", undefined) &&
      plan &&
      getPlan(plan) === "starter"
    ) {
      return openDialog({ type: "PROMOTE_PUSH" })
    }

    if (type === "reservation") {
      return sendPush({
        ...data,
        datetime: values.datetime,
        images: get(values, "images", undefined),
        type: "reservation",
      })
    }

    sendPush({
      ...data,
      images: get(values, "images", undefined),
      type: "basic",
    })
  }

  const handleChangeDatetime = datetime => {
    const nextDatetime = moment(datetime).toDate()

    setValidateErrors(
      produce(draft => {
        delete draft.reservation["date"]
      })
    )

    setData(
      produce(draft => {
        draft.datetime = nextDatetime
      })
    )
  }

  const onSubmit = (data, type) => {
    if (type === "reservation") {
      handleReservationOpen()
    }
    if (type === "basic") {
      handleSendPush("basic")
    }
  }

  const handleReservationOpen = meta => {
    const roundedUp = Math.ceil(moment().minute() / 10) * 10

    const nextDateTime = moment().minute(roundedUp).second(0).toDate()

    setData(
      produce(draft => {
        draft.datetime = nextDateTime
      })
    )

    setReservation(
      produce(draft => {
        draft.isOpen = true
        draft.meta = meta
      })
    )
  }

  const handleReservationAction = () => {
    if (!data.datetime) {
      return false
    }

    const diff = moment(data.datetime).diff(moment())

    if (diff <= 0) {
      return setValidateErrors(
        produce(draft => {
          draft.reservation["date"] = true
        })
      )
    }
    handleSendPush("reservation")
    onRequestReservationClose()
  }

  const onRequestReservationClose = () => {
    setReservation(
      produce(draft => {
        draft.isOpen = false
      })
    )
  }

  const handleChangeText = event => {
    const name = event.target.name
    const value = event.target.value

    setValue(name, value)

    setData(
      produce(draft => {
        draft[name] = value
      })
    )
  }

  const handleSearchModalClose = () => {
    setSearch(
      produce(draft => {
        draft.isOpen = false
      })
    )
  }
  const handleSearchPrimary = (id, content) => {
    handleSearchModalClose()

    loadProductImages({
      variables: { ids: [id] },
    })

    setData(
      produce(draft => {
        draft.product = {
          id,
          images: get(content, "images.edges"),
          title: content.title,
        }
      })
    )
  }

  return (
    <Page
      title="Push Notification"
      secondaryActions={[
        {
          content: (
            <div className="flex flex-row items-center">
              <svg
                className="w-4 h-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M3 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="ml-2">History</span>
            </div>
          ),
          onAction: () => navigate("/app/push/history"),
        },
      ]}
    >
      <div className="flex flex-col lg:flex-row flex-wrap w-full">
        <div className="order-2 lg:order-2 pr-0 lg:pr-8 hidden lg:block">
          <Device
            expended={expended}
            setExpended={setExpended}
            appName={appName}
            appIcon={appIcon}
            title={data.title}
            message={data.message}
            thumbnails={get(data.product, "images", undefined)}
          />
        </div>
        <div className="flex-1 flex flex-col justify-center order-1 lg:order-2">
          <div className="bg-white shadow-md rounded-sm px-4 md:px-8 py-6 lg:py-8">
            <form>
              <input
                type="hidden"
                ref={register}
                name="datetime"
                value={data.datetime}
                onChange={handleChangeDatetime}
              />
              <FormLayout>
                <TextField
                  label="Push notification title (optional)"
                  name="title"
                  // value={data.title}
                  maxLength={40}
                  placeholder="ex) Hey Everyone"
                  ref={register}
                  onChange={handleChangeText}
                />
                <TextField
                  label="Message"
                  name="message"
                  // value={data.message}
                  placeholder="Enter your marketing message here to boost your sales. ex) 80% OFF only Black Friday one day!"
                  ref={register({ required: true })}
                  multiline={5}
                  onChange={handleChangeText}
                  maxLength={255}
                  disabledEnter={true}
                  error={errors.message && <span>This field is required</span>}
                />
                <div>
                  <h4 css={tw`block text-base text-gray-900 font-medium mb-2`}>
                    Attach a product feature (optional)
                  </h4>
                  {data.product ? (
                    <div className="flex flex-row flex-wrap">
                      <div
                        css={[
                          tw`relative overflow-hidden`,
                          css`
                            width: 102px;
                          `,
                        ]}
                      >
                        <div css={tw`pb-1/1 bg-white`}></div>
                        <img
                          src={get(data, "product.images.0.node.src")}
                          alt=""
                          css={tw`bg-white absolute inset-0 object-cover`}
                        />
                      </div>
                      <div className="ml-2 md:ml-4 flex-1 flex flex-col justify-center items-start">
                        <h3 css={tw`font-medium text-sm leading-tight`}>
                          {get(data, "product.title")}
                        </h3>
                        <input
                          type="hidden"
                          ref={register}
                          name="images"
                          value={reduce(
                            map(get(data, "product.images"), item =>
                              get(item, `node.src`)
                            ),
                            (acc, curr) => (curr ? `${acc},${curr}` : acc)
                          )}
                        />
                        <input
                          type="hidden"
                          ref={register}
                          name="targetId"
                          value={get(data, "product.id")}
                        />
                        <input
                          type="hidden"
                          ref={register}
                          name="targetType"
                          value={"product"}
                        />
                        <button
                          css={tw`text-primary-500 text-sm mt-2 flex flex-row items-center`}
                          onClick={() => {
                            setData(
                              produce(draft => {
                                draft.product = null
                              })
                            )
                            setExpended(false)
                          }}
                        >
                          <svg
                            css={tw`w-4 h-4 mr-1 `}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                              clipRule="evenodd"
                            />
                          </svg>{" "}
                          <span>Delete</span>
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div
                      css={tw`flex flex-row cursor-pointer`}
                      onClick={() =>
                        setSearch(
                          produce(draft => {
                            draft.isOpen = true
                          })
                        )
                      }
                    >
                      <div
                        css={css`
                          width: 102px;
                        `}
                      >
                        <img src={ImgProductPlaceholder} alt="" />
                      </div>
                      <div css={tw`flex flex-col justify-center ml-2 md:ml-4`}>
                        <h4 css={tw`font-medium text-sm leading-tight`}>
                          Choose the product
                        </h4>
                        <p css={tw`text-gray-600 text-sm`}>
                          to attract customer's attention
                        </p>
                      </div>
                    </div>
                  )}

                  <p css={tw`text-gray-600 text-sm mt-2`}>
                    #Animated image push is supported in iOS.
                  </p>
                </div>
                <div className="flex flex-row flex-wrap -mx-1 justify-end">
                  <div className="w-full mx-0 md:mx-1 md:flex-1">
                    <Button
                      type="button"
                      fullWidth
                      onClick={() => {
                        if (!isTrialExpired) {
                          return handleSubmit(data =>
                            onSubmit(data, "reservation")
                          )()
                        }

                        if (!paid) {
                          if (isTrialExpired) {
                            return openDialog({ type: "PROMOTE_PUSH" })
                          }
                        }

                        if (plan && getPlan(plan) === "starter") {
                          return openDialog({ type: "PROMOTE_PUSH" })
                        }

                        handleSubmit(data => onSubmit(data, "reservation"))()
                      }}
                    >
                      <div className="inline-flex align-center">
                        <span>SEND LATER</span>
                        <svg
                          css={tw`w-4 h-4 inline-block ml-2`}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                    </Button>
                  </div>
                  <div className="w-full mx-0 md:mx-1 mt-2 md:mt-0 md:flex-1">
                    <Button
                      primary
                      fullWidth
                      type="button"
                      onClick={() => {
                        if (!plan && isTrialExpired) {
                          return openDialog({ type: "TRIAL_EXPIRED" })
                        }

                        handleSubmit(data => onSubmit(data, "basic"))()
                      }}
                    >
                      <div className="inline-flex align-center">
                        <span>SEND NOW</span>
                        <svg
                          css={tw`w-4 h-4 inline-block ml-2`}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                    </Button>
                  </div>
                </div>
              </FormLayout>
            </form>
          </div>
        </div>
      </div>
      <PushReservationModal
        meta={{ errors: validateErrors.reservation }}
        isOpen={reservation.isOpen}
        datetime={data.datetime}
        handleChangeDatetime={handleChangeDatetime}
        onAction={handleReservationAction}
        onRequestClose={onRequestReservationClose}
      />
      <ShopifyModalProvider
        meta={search.meta}
        isOpen={search.isOpen}
        disabledEmpty={true}
        onPrimaryAction={handleSearchPrimary}
        onRequestClose={handleSearchModalClose}
      />
      <FeedbackModal
        isOpen={feedback}
        title={
          type === "reservation"
            ? `Great ! Scheduled.`
            : `Push notification sent successfully.`
        }
        source={PushSuccess}
        onRequestClose={() => setFeedback(false)}
        primaryAction={{
          content: "Ok",
          onAction: () => setFeedback(false),
        }}
        onAfterClose={() => resetPush()}
      />
      {isLoading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <LogoSpinner />
          <Overlay light />
        </div>
      )}
    </Page>
  )
}

const mapStateToProps = state => {
  const profile = state.firebase.profile
  const appName = get(profile, "settings.app_name", "")
  const appIcon = get(profile, "settings.app_icon", JcurveLogo)

  return {
    appName,
    appIcon,
    isLoading: state.push.isLoading,
    isEmpty: state.push.isEmpty,
    type: state.push.type,
    status: state.push.status,
  }
}

const mapDispatchToProps = { sendPush, resetPush, openDialog }

export default connect(mapStateToProps, mapDispatchToProps)(PushPage)
