import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import { css } from "twin.macro"
import { useSelector } from "react-redux"
import { filter, orderBy, isNull, get } from "lodash"
import firebase from "gatsby-plugin-firebase"

import Page from "general/components/Page"
import Card from "general/components/Card"
import Ribbon from "general/components/Ribbon"
import Overlay from "general/components/Overlay"
import SkeletonBodyText from "general/components/SkeletonBodyText"

import Hero from "assets/images/integration/hero.jpg"

const ServiceItem = ({
  onAction,
  title,
  category,
  content,
  thumbnail,
  available,
}) => {
  return (
    <div
      className="group relative flex flex-row flex-wrap cursor-pointer rounded-sm shadow-sm border border-gray-100 py-3 px-3 hover:border-primary-500 transition duration-150"
      onClick={onAction}
    >
      {!available && <Ribbon>Coming</Ribbon>}
      <img
        src={thumbnail}
        alt={title}
        className="mr-4 inline-block w-12 h-12 bg-white rounded-md"
      />
      <div className="flex-1">
        <h3 className="text-lg font-semibold text-gray-900 leading-none">
          {title}
        </h3>
        <p className="text-sm text-gray-600 capitalize">{category}</p>
        <p className="text-gray-600">{content}</p>
      </div>
      {!available && (
        <div className="absolute inset-0 hidden items-center justify-center group-hover:flex">
          <h3 className="text-center text-lg text-black z-30 relative">
            Coming soon!
          </h3>
          <Overlay light />
        </div>
      )}
    </div>
  )
}

export default function IntegrationPage() {
  const [services, setServices] = useState([])
  const [installedServices, setInstalledServices] = useState(null)
  const profile = useSelector(state => state.firebase.profile)
  const checkList = ["google_analytics", "judge_me", "stamped_io"]
  const currentVersion = get(profile, "settings.current_version", 1)
  const shop = profile.shopify_url

  useEffect(() => {
    async function fetchIntegration() {
      const promise = [
        firebase
          .firestore()
          .collection("_integrations")
          .orderBy("order", "asc")
          .get(),
        firebase
          .firestore()
          .collection(shop)
          .doc("apps")
          .collection("integrations")
          .get(),
      ]
      const [serviceSnapshot, installedSnapshot] = await Promise.all(promise)

      const nextServices = []
      const nextInstalledServices = []

      serviceSnapshot.forEach(doc => {
        nextServices.push({ id: doc.id, ...doc.data() })
      })

      installedSnapshot.forEach(doc => {
        nextInstalledServices.push({ id: doc.id, ...doc.data() })
      })

      setServices(nextServices)
      setInstalledServices(nextInstalledServices)
    }

    fetchIntegration()
  }, [])

  const powerPlan = filter(services, item => (item.minimum_plan === "power" || item.minimum_plan === "enterprise"))
  const enterprisePlan = filter(
    services,
    item => item.minimum_plan === "enterprise"
  )

  const handleNavigate = item => {
    if (item.available) {
      return navigate(`/app/integration/${item.id}`, {
        state: {
          thumbnail: item.logo,
          title: item.name,
          content: item.summary,
          category: item.category,
          plan: item.minimum_plan,
        },
      })
    }
  }

  return (
    <Page
      title="Integration - enhance your app"
      subtitle="JCURVE works with your popular apps"
    >
      <div className="grid grid-cols-1 gap-2">
        <Card>
          <img src={Hero} alt="" h-full w-full/>
        </Card>
        <Card title="Connected Integrations">
          {isNull(installedServices) ? (
            <SkeletonBodyText />
          ) : (
            <div className="mt-4 grid grid-cols-1 md:grid-cols-2 row-gap-4 md:row-gap-6 col-gap-4">
              {installedServices.length === 0 && (
                <div
                  className="group inline-flex items-center justify-center text-gray-900 border-2 border-dotted border-gray-300 py-4 px-4 cursor-pointer"
                  css={css`
                    height: 90px;
                  `}
                >
                  <p className="inline-block group-hover:hidden">
                    No apps selected yet.
                  </p>
                  <p className="hidden group-hover:inline-block">
                    Find integrations to improve revenue!
                  </p>
                </div>
              )}
              {installedServices.map(item => {
                const service = get(
                  services.filter(service => service.name === item.id),
                  0,
                  undefined
                )

                if (service) {
                  return (
                    <div key={service.id}>
                      <ServiceItem
                        key={service.name}
                        thumbnail={service.logo}
                        title={service.name}
                        content={service.summary}
                        category={service.category}
                        available={service.available}
                        onAction={() => handleNavigate(service)}
                      />
                    </div>
                  )
                }
              })}
            </div>
          )}
        </Card>
        <Card title="Best Integrations">
          {powerPlan.length > 0 ? (
            <div className="mt-4 grid grid-cols-1 md:grid-cols-2 row-gap-4 md:row-gap-6 col-gap-4">
              {orderBy(powerPlan, object => object.order).map(item => {
                /**
                 * current_version을 통해 google analytics, judge.me, stamped.io
                 * 이 3가지 앱을 일부 고객 및 신규 고객에게만 적용하기 위한 작업
                 * current_version이 1.1 이상인 고객 ( 신규고객의 current_version을 1.1로 변경 )
                 */
                 
                if (checkList.includes(item.id) && currentVersion < 1.1) { //조건 : 3가지 앱인경우, 1.0이면 안보이게 수정 
                  item.available = false
                }

                return (
                  <ServiceItem
                    key={item.name}
                    thumbnail={item.logo}
                    title={item.name}
                    content={item.summary}
                    category={item.category}
                    available={item.available}
                    onAction={() => handleNavigate(item)}
                  />
                )
              })}
            </div>
          ) : (
            <SkeletonBodyText />
          )}
        </Card>
        {/* <Card title="Enterprise Plan Available">
          {enterprisePlan.length > 0 ? (
            <div className="mt-4 grid grid-cols-1 md:grid-cols-2 row-gap-4 md:row-gap-6 col-gap-4">
              {orderBy(enterprisePlan, object => object.order).map(item => (
                <ServiceItem
                  key={item.name}
                  thumbnail={item.logo}
                  title={item.name}
                  content={item.summary}
                  category={item.category}
                  available={item.available}
                  onAction={() => handleNavigate(item)}
                />
              ))}
            </div>
          ) : (
            <SkeletonBodyText />
          )}
        </Card> */}
      </div>
    </Page>
  )
}
