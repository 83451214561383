import React, { forwardRef, useRef, useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import tw from "twin.macro"
import { useSelector } from "react-redux"
import { useFirebase } from "react-redux-firebase"
import { has, includes } from "lodash"

import ProgressSpinner from "general/components/ProgressSpinner"
import noImagePng from "assets/images/design/bg-menu-item-noimage.png"

const PreviewImage = styled.img`
  ${tw`absolute inset-0 object-scale-down w-full h-full table-cell align-middle`}

  ${({ preview }) => preview && tw`object-cover`}
  ${({ fixedRatio }) =>
    fixedRatio && [
      tw`object-cover`,
      css`
        margin-bottom: ${parseFloat(fixedRatio) * 100}%;
      `,
    ]}
`

const FormImageUpload = forwardRef(
  ({ emptyImage, source, onAction, fixedRatio }, ref) => {
    const [preview, setPreview] = useState(undefined)
    const [progress, setProgress] = useState(undefined)
    const [dimensions, setDimensions] = useState(undefined)
    const [task, setTask] = useState(null)
    const firebase = useFirebase()
    const inputRef = useRef(null)
    const profile = useSelector(state => state.firebase.profile)

    useEffect(() => {
      let loaded = true

      if (loaded) {
        setPreview(source)
      }

      return () => {
        if (task !== null) {
          loaded = false
          task.cancel()
        }
      }
    }, [source, task])

    const verifyImageProcess = file => {
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.src = window.URL.createObjectURL(file)
        img.onload = function () {
          const width = img.naturalWidth
          const height = img.naturalHeight

          resolve({ width, height, file })
        }
      })
    }

    const handleChange = event => {
      if (event.target.files.length === 0) {
        return false
      }
      const file = event.target.files[0]

      if (!includes(["image/jpeg", "image/png"], file.type)) {
        return alert("Your file must be in PNG or JPG format.")
      }

      verifyImageProcess(file).then(data => {
        const { width, height, file } = data
        const ratio = ((height * 1.0) / (width * 1.0)).toFixed(3)

        if (width > 2000 || height > 2000 || width < 500 || height < 50) {
          return alert(
            "Please check your image size. Minimum size is 500 x 50 pixels. Maximum size is 2,000 x 2,000 pixels."
          )
        }

        if (has(profile, "shopify_url") && file) {
          setProgress(0)

          const storageRef = firebase.storage().ref()
          const shopify_url = profile.shopify_url
          const fileRef = storageRef.child(
            `app/${shopify_url.replace(
              ".myshopify.com",
              ""
            )}/blocks/${Date.now()}_${file.name}`
          )

          const uploadTask = fileRef.put(file)
          setTask(uploadTask)

          uploadTask.on(
            "state_changed",
            function (snapshot) {
              const progress = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              )

              setProgress(progress)

              switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED:
                  break
                case firebase.storage.TaskState.RUNNING:
                  break
              }
            },
            function (error) {
              if (task !== null) {
                setProgress(undefined)
                window.Sentry.captureException(error)
              }
            },
            function () {
              uploadTask.snapshot.ref.getDownloadURL().then(downLoadUrl => {
                onAction(downLoadUrl, { width, height, ratio })
                setProgress(undefined)
                setPreview(undefined)
                setTask(null)

                if (ref) {
                  ref.current.value = null
                } else {
                  inputRef.current.value = null
                }
              })
            }
          )
        }
      })
    }

    const handleLoad = img => {
      setDimensions({ height: img.offsetHeight, width: img.offsetWidth })
    }

    return (
      <div>
        <div css={[tw`relative text-center overflow-hidden`]}>
          <div css={tw`relative`}>
            <label
              css={[tw`w-full h-full shadow-lg cursor-pointer text-gray-800`]}
            >
              <div
                css={[
                  css`
                    padding-bottom: ${parseFloat(fixedRatio) * 100}%;
                  `,
                ]}
              ></div>

              {progress >= 0 ? (
                <div
                  css={tw`absolute inset-0 w-full h-full flex items-center justify-center`}
                >
                  <div css={tw`w-16`}>
                    <ProgressSpinner percentage={progress} />
                  </div>
                </div>
              ) : (
                <PreviewImage
                  onLoad={handleLoad}
                  preview={!preview && true}
                  src={preview ? preview : noImagePng}
                  alt="preview"
                  fixedRatio={fixedRatio}
                />
              )}

              <input
                ref={element => {
                  if (ref) {
                    ref.current = element
                  }

                  inputRef.current = element
                }}
                type="file"
                required
                name="targetUrl"
                css={tw`hidden`}
                onChange={handleChange}
              />
            </label>
          </div>
        </div>
      </div>
    )
  }
)

FormImageUpload.defaultProps = {
  fixedRatio: 0.75,
}

export default FormImageUpload
