import React from "react"
import { navigate } from "gatsby"
import { css } from "twin.macro"

import Card from "general/components/Card"
import Button from "general/components/Button"
import ListingTimeline from "general/components/ListingTimeline"

import IconAndroid from "assets/images/pricing/icon-android.png"
import GuidelineAndroid from "assets/images/listing/setup-guideline-android-invite.png"

export default function AndroidSyncCard({ checked }) {
  if (checked) {
    return (
      <Card title={`Connect your Apple Developer account`}>
        <p className="mb-4">
          Invite us to your Play Console as a Admin Developer accounts.
        </p>
        <ListingTimeline>
          <ListingTimeline.Item
            color="#79c257"
            icon={
              <img
                src={IconAndroid}
                css={css`
                  width: 15px;
                  height: 15px;
                `}
              />
            }
            label="STEP 1"
          >
            <div className="shadow-sm px-4 py-4 border border-gray-300">
              <h3 className="mb-2 font-medium text-lg text-gray-900">
                Invite jay@jcurve.xyz
              </h3>
              <p className="leading-snug mb-4">
                Navigate to your Google Play{" "}
                <span className="text-gray-900">
                  Console > Settings > Users & Permissions
                </span>{" "}
                section. Click on{" "}
                <span className="text-gray-900">“New User Invite”</span>.
              </p>
              <a
                href="https://play.google.com/apps/publish/"
                target="_blank"
                className="text-primary-500 font-medium text-base"
              >
                Android Developer Account Invite ↗
              </a>
            </div>
          </ListingTimeline.Item>
          <ListingTimeline.Item
            color="#79c257"
            icon={
              <img
                src={IconAndroid}
                css={css`
                  width: 15px;
                  height: 15px;
                `}
              />
            }
            label="STEP 2"
          >
            <div className="shadow-sm px-4 py-4 border border-gray-300">
              <h3 className="mb-2 font-medium text-lg text-gray-900">
                Invite jay@jcurve.xyz
              </h3>
              <p className="leading-snug mb-4">
                Set <span className="text-gray-900">jay@jcurve.xyz</span> on{" "}
                <span className="text-gray-900">Email</span> section, select{" "}
                <span className="text-gray-900">"Never"</span> on
                <span className="text-gray-900">Access expiry date</span>, Set{" "}
                <span className="text-gray-900">Role</span> as{" "}
                <span className="text-gray-900">"Administrator"</span>. Click on{" "}
                <span className="text-gray-900">"Send Invitation"</span>.
              </p>
              <img
                src={GuidelineAndroid}
                alt="Android Invite"
                className="w-full"
              />
            </div>
            <div className="mt-8">
              <h4 className="text-xl text-gray-900 mb-4">
                Once you invite{" "}
                <span className="text-primary-500">jay@jcurve.xyz</span>
                <br />
                you will be able to publish your app.
              </h4>
              <Button primary onClick={() => navigate("/app/listing/status")}>
                APPLICATION STATUS
              </Button>
            </div>
          </ListingTimeline.Item>
        </ListingTimeline>
      </Card>
    )
  }

  return (
    <Card title={`We already start to publish your app.`}>
      <ListingTimeline>
        <ListingTimeline.Item
          color="#79c257"
          icon={
            <img
              src={IconAndroid}
              css={css`
                width: 15px;
                height: 15px;
              `}
            />
          }
          label="DONE"
        >
          <div className="shadow-sm mb-8 px-4 py-4 border border-gray-300">
            <h3 className="mb-2 font-medium text-lg text-gray-900">All set!</h3>
            <p className="leading-tight">
              We will publish your app under our developer account. <br />
              You can save{" "}
              <span className="font-medium text-gray-900">$25(one time)</span>
            </p>
          </div>
          <div className="mt-8">
            <h4 className="text-xl text-gray-900 mb-4">
              When you app is live, we will let you know!
            </h4>
            <Button primary onClick={() => navigate("/app/listing/status")}>
              APPLICATION STATUS
            </Button>
          </div>
        </ListingTimeline.Item>
      </ListingTimeline>
    </Card>
  )
}
