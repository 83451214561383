import React, { useState, useEffect } from "react"
import tw, { css } from "twin.macro"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import SwitchSelector from "react-switch-selector"
import { get, includes, isUndefined } from "lodash"
import produce from "immer"
import styled from "styled-components"
import queryString from "query-string"

import { requestPayment } from "services/shopify"
import { getPlan } from "auth/services"
import { track } from "services/analytics"

import Button from "general/components/Button"
import PricingRibbon from "general/components/PricingRibbon"
import CustomPaymentModal from "modal/components/CustomPaymentModal"
import PricingPromotion from "assets/images/pricing-promotion.jpg"
import IconStarter from "assets/images/pricing/icon-starter.jpg"
import IconGrowth from "assets/images/pricing/icon-growth.jpg"
import IconPower from "assets/images/pricing/icon-power.jpg"
import IconEnterprise from "assets/images/pricing/icon-enterprise.jpg"
import IconFacebook from "assets/images/pricing/icon-facebook.png"
import IconWechat from "assets/images/pricing/icon-wechat.png"
import IconAndroid from "assets/images/pricing/icon-android.png"
import IconApple from "assets/images/pricing/icon-apple.png"
import LogoShopify from "assets/images/pricing/logo-shopify-plus.png"

const PromoYearlyFlag = styled.div`
  ${tw`absolute inline-block text-white`}
  padding: 4px 12px;
  font-size: 23px;
  font-weight: bold;
  background: #7ed6df;
  animation: pop 2s forwards infinite;
  top: 48px;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 0;
    height: 0;
    top: -10px;
    border-bottom: 10px solid #7ed6df;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
  }

  @keyframes pop {
    0% {
      transform: scale(1, 1) skew(0deg);
    }
    50% {
      transform: scale(0.8, 0.8) skew(0deg);
    }
    100% {
      transform: scale(1, 1) skew(0deg);
    }
  }
`

const IconChecked = () => {
  return (
    <svg
      className="inline-block w-6 h-6 text-green-600"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M5 13l4 4L19 7"
      />
    </svg>
  )
}

const Icon = ({ source }) => {
  return <img src={source} alt="" className="w-6 h-6 inline-block" />
}

const IconX = () => {
  return (<p>-</p>)
  // return (
  //   <svg
  //     className="inline-block w-6 h-6 text-red-600"
  //     xmlns="http://www.w3.org/2000/svg"
  //     fill="none"
  //     viewBox="0 0 24 24"
  //     stroke="currentColor"
  //   >
  //     <path
  //       strokeLinecap="round"
  //       strokeLinejoin="round"
  //       strokeWidth={3}
  //       d="M6 18L18 6M6 6l12 12"
  //     />
  //   </svg>
  // )
}

const Switch = ({ options, onAction, selectedIndex }) => {
  // const initialSelectedIndex = options.findIndex(({ value }) => value === "bar")

  return (
    <div
      className="your-required-wrapper border border-primary-500 rounded-full"
      style={{ width: "100%", height: 38 }}
    >
      <SwitchSelector
        onChange={onAction}
        options={options}
        initialSelectedIndex={selectedIndex}
        fontSize={20}
        backgroundColor={"#ffffff"}
        fontColor={"#d2025a"}
        selectedFontColor={"#ffffff"}
      />
    </div>
  )
}

export default function PricingPage(props) {
  const location = props.location
  const params = location.search ? queryString.parse(location.search) : {}
  console.log("pricing", params)
  const [customModal, setCustomModal] = useState(false)
  const [loading, setLoading] = useState({
    starter: false,
    growth: false,
    power: false,
    enterprise: false,
  })
  const profile = useSelector(state => state.firebase.profile)

  const currentPlan = get(profile, "payment.plan", undefined)
  const shouldShowStarterOption = ["starter", "starter_yearly"].includes(currentPlan) ? true : (isUndefined(currentPlan) ? true : false)
  //현재 starter를 쓰고 있거나 결제plan이 없는 고객에게는 Starter 옵션을 보여준다. 
  console.log("currentPlan >" ,currentPlan , "shouldShowStarterOption > ", shouldShowStarterOption)
  console.log("[custom] url params --> ", params)
  const [period, setPeriod] = useState(
    includes(currentPlan, "_yearly")
      ? "yearly"
      : isUndefined(currentPlan)
      ? "yearly"
      : "monthly"
  )

  useEffect(() => {
    if (params.custom) {
      console.log("[custom] setCustomModal is true")
      setCustomModal(true)
    }
  }, [])

  const plans = [
    {
      id: "starter",
      content: "Launch iOS or Android",
    },
    { id: "growth", content: "Ideal for New Business"},
    { id: "power", content: "Ideal for Growing Business"},
    {
      id: "enterprise",
      content: "Ideal for Shopify Plus business",
    },
  ]
  const monthlyPlan = {
    starter: {
      priceMonth: "49.99",
      priceYear: "599",
      priceYearPerMonth: "34.16",
    },
    growth: {
      priceMonth: "89",
      priceYear: "1,068",
      priceYearPerMonth: "71",
    },
    power: {
      priceMonth: "139",
      priceYear: "1,668",
      priceYearPerMonth: "111",
    },
    platinum: {
      priceMonth: "399",
      priceYear: "3,830",
      priceYearPerMonth: "320",
    },
    enterprise: {
      priceMonth: "CUSTOM",
      priceYear: "CUSTOM",
      priceYearPerMonth: "CUSTOM",
    },
  }
  const yearlyPlan = {
    starter: {
      priceMonth: "49.99",
      priceYear: "410",
      priceYearPerMonth: "34.16",
    },
    growth: {
      priceMonth: "89",
      priceYear: "740",
      priceYearPerMonth: "62",
    },
    power: {
      priceMonth: "139",
      priceYear: "1,160",
      priceYearPerMonth: "97",
    },
    platinum: {
      priceMonth: "399",
      priceYear: "3,830",
      priceYearPerMonth: "320",
    },
    enterprise: {
      priceMonth: "CUSTOM",
      priceYear: "CUSTOM",
      priceYearPerMonth: "CUSTOM",
    },
  }

  const handleClickPayment = async name => {
    if (name === "enterprise") {
      return window.Intercom("show")
    }

    setLoading(
      produce(draft => {
        draft[name] = true
      })
    )

    let interval = "EVERY_30_DAYS"

    if (period === "yearly") {
      interval = "ANNUAL"
    }

    const data = {
      name,
      interval,
    }

    let value = monthlyPlan[name].priceMonth

    if (period === "yearly") {
      value = yearlyPlan[name].priceYear
    }

    const analyticsData = {
      currency: "USD",
      value,
      name,
      interval,
    }

    track("INITIATE_CHECKOUT", analyticsData)

    const paymentUrl = await requestPayment(data)

    if (paymentUrl) {
      return (window.top.location.href = paymentUrl)
    }

    toast("Invalid request")
  }

  const getPrice = type => {
    if (period === "monthly") {
      return `$${monthlyPlan[type].priceMonth}/mo`
    }
    if (period === "yearly") {
      return `$${yearlyPlan[type].priceYearPerMonth}/mo`
    }
  }
  
  const renderPlanTitleClass = plan => {
    return 'mt-4 font-serif text-xl font-semibold capitalize'

    //TODO : 플랜별로 color 설정 
    console.log("plan ---->" , plan)
    if (plan === "starter") {
      return 'mt-4 font-serif text-xl font-semibold capitalize bg-yellow-500'
    } else if (plan === "growth") {
      return 'mt-4 font-serif text-xl font-semibold capitalize bg-green-500'
    } else if (plan === "power") {
      return 'mt-4 font-serif text-xl font-semibold capitalize bg-pink-500'
    } else {
      return 'mt-4 font-serif text-xl font-semibold capitalize bg-pink-500'
    }
  }

  const getYearPrice = type => {
    if (period === "monthly") {
      return <div className="mb-2">-</div>
    }
    if (period === "yearly") {
      console.log(monthlyPlan[type])
      if (monthlyPlan[type].priceYear === "CUSTOM") {
        return (
          <div className="mb-2">
            <span className="text-gray-600">Custom price</span>
          </div>
        )
      } else {
      return (
        <div className="mb-2">
          (
          <span className="line-through text-gray-600 text-sm mr-1">{`$${monthlyPlan[type].priceYear}`}</span>
          <span className="text-primary-500">{`$${yearlyPlan[type].priceYear}`}</span>
          /year)
        </div>
        )
     }
  }
  }

  const renderButton = plan => {
    let selectedPlan = plan

    if (period === "yearly") {
      selectedPlan = `${plan}_yearly`
    }

    if (selectedPlan === currentPlan) {
      return (
        <Button onClick={event => event.preventDefault()} fullWidth plain>
          <span className="font-medium uppercase">CURRENT PLAN</span>
        </Button>
      )
    }

    let disabled = selectedPlan === currentPlan

    if (period === "monthly") {
      if (includes(currentPlan, "_yearly")) {
        if (currentPlan.replace("_yearly", "") === plan) {
          disabled = true
        }
        if (includes(["starter", "growth", "power", "platinum"], plan)) {
          disabled = true
        }
      }
    }

    return (
      <Button
        disabled={disabled}
        primary={plan !== "enterprise"}
        yellow={plan === "enterprise"}
        loading={loading[plan]}
        onClick={() => handleClickPayment(plan)}
        fullWidth
      >
        <span className="font-medium uppercase">
          {plan === "enterprise" ? "Contact" : "GET STARTED"}
        </span>
      </Button>
    )
  }

  return (
    <div className="bg-white ">
      {params.price && params.name && (
        <CustomPaymentModal
          isOpen={customModal}
          price={params.price}
          name={params.name}
          message={params.message}
          paymentType={params.type}
          onRequestClose={() => setCustomModal(false)}
        />
      )}
      <div className="max-w-screen-xl mx-auto">
        <img src={PricingPromotion} alt="" className="w-full py-0 transform scale-75" />
        <div className="mx-auto px-4 lg:px-6 text-center max-w-2xl py-0">
          <h2 className="text-3xl font-bold leading-tight">
          Find a plan that’s right for you<br/>
          </h2>
          {isUndefined(currentPlan) && (
            <p className="text-gray-600 mt-2">
              *App publishing requires {" "}
              <span className="text-primary-500">Active subscription.</span>
            </p>
          )}
        </div>
        <div
          className={
            shouldShowStarterOption
              ? "grid grid-cols-1 lg:grid-cols-5 gap-4 py-8"
              : "grid grid-cols-1 lg:grid-cols-4 gap-4 py-8"
          }
        >
          <div className="text-center py-4 flex items-center relative">
            <div className="text-center w-full">
              <h2 className="text-1xl leading-tight text-primary-500">
                Get 30% OFF for Yearly Plan<br />
                (~5/31)
              </h2>
              <div className="mt-4">
                <div className="relative">
                  <Switch
                    selectedIndex={period === "monthly" ? 0 : 1}
                    border={1}
                    onAction={event => {
                      setPeriod(event)
                    }}
                    options={[
                      {
                        label: "Monthly",
                        value: "monthly",
                        selectedFontColor: "#ffffff",
                        selectedBackgroundColor: "#d2025a",
                      },
                      {
                        label: "Yearly",
                        value: "yearly",
                        selectedFontColor: "#ffffff",
                        selectedBackgroundColor: "#d2025a",
                      },
                    ]}
                  />
                  <PromoYearlyFlag>SAVE 30%</PromoYearlyFlag>
                </div>
              </div>
            </div>
          </div>
          {plans.map(plan => {
            const isActive = plan.id === getPlan(currentPlan)

            if (plan.id === "starter" && !shouldShowStarterOption) {
              return null
            }

            return (
              <div
                key={plan.id}
                className="text-center px-4 py-4 relative"
                css={
                  isActive && [
                    css`
                      box-shadow: 1px 1px 8px rgba(173, 173, 173, 0.3);
                    `,
                  ]
                }
              >
                {isActive && <PricingRibbon>CURRENT</PricingRibbon>}
                {!isActive && plan.id == "power" && <PricingRibbon>POPULAR</PricingRibbon>}
                <img
                  src={plan.icon}
                  alt=""
                  css={[
                    tw`mx-auto`,
                    css`
                      width: 130px;
                    `,
                  ]}
                />
                <h3 className={renderPlanTitleClass(plan)}>
                  {plan.id}
                </h3>
                <h4 className="text-lg font-semibold my-2">
                  {getPrice(plan.id)}
                </h4>
                {getYearPrice(plan.id)}
                <p
                  className={
                    shouldShowStarterOption
                      ? "text-gray-600 mb-4 text-sm"
                      : "text-gray-600 mb-4"
                  }
                >
                  {plan.content}
                </p>
                {renderButton(plan.id)}
              </div>
            )
          })}
        </div>
        <div className="border-t border-gray-300">
          <table
            className="table-auto w-full"
            css={css`
              td {
                ${tw`text-gray-800 text-center py-3 px-2 border-b border-t border-gray-100`}

                font-size: 14px;

                &:first-child {
                  ${tw`text-left`}
                }
              }
            `}
          >
            {shouldShowStarterOption ? (
              <colgroup>
                <col className="w-1/5" />
                <col className="w-1/5" />
                <col className="w-1/5" />
                <col className="w-1/5" />
              </colgroup>
            ) : (
              <colgroup>
                <col className="w-1/4" />
                <col className="w-1/4" />
                <col className="w-1/4" />
                <col className="w-1/4" />
              </colgroup>
            )}
            <tbody>
              <tr className="bg-gray-100">
                <td className="font-medium text-lg" colSpan={shouldShowStarterOption ? 4 : 3}>
                  Basic Features
                </td>
                <td rowSpan={17} className="bg-white align-top">
                  <div
                    css={[
                      tw`text-left px-2`,
                      css`
                        border: 3px solid #f8b500;
                      `,
                    ]}
                  >
                    <ul
                      css={[
                        tw`my-4`,
                        css`
                          li {
                            ${tw`pl-6 relative`}
                          }
                          li::before {
                            ${tw`w-4 h-4 inline-block absolute left-0`}
                            content: "";
                            top: 3px;
                            color: #f8b500;
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%23f8b500'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M5 13l4 4L19 7' /%3E%3C/svg%3E");
                          }
                        `,
                      ]}
                    >
                      <li>
                        <img
                          src={LogoShopify}
                          alt="Shopify Plus"
                          css={tw`w-auto h-6`}
                        />
                      </li>
                      <li className="mt-2">Support Customization</li>
                      <li className="mt-2">Power plan all features</li>
                      <li className="mt-2">Multi Language Support</li>
                      <li className="mt-2">Multi Currency</li>
                      <li className="mt-2">
                        AI-powered Conversion Optimization
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Native iOS and Android App</td>
                {shouldShowStarterOption && (
                  <td>
                    <div className="inline-flex items-center">
                    <span className="font-semibold mx-2">Pick</span>
                      <Icon source={IconApple} />{" "}
                      <span className="font-semibold mx-2">or</span>
                      <Icon source={IconAndroid} />
                    </div>
                  </td>
                )}
                <td>
                  <div className="inline-flex items-center">
                    <Icon source={IconApple} />
                    <Icon source={IconAndroid} />
                  </div>
                </td>
                <td>
                  <div className="inline-flex items-center">
                    <Icon source={IconApple} />
                    <Icon source={IconAndroid} />
                  </div>
                </td>
              </tr>
              <tr>
                <td>Real-time updates</td>
                {shouldShowStarterOption && (
                  <td>
                    <IconChecked />
                  </td>
                )}
                <td>
                  <IconChecked />
                </td>
                <td>
                  <IconChecked />
                </td>
              </tr>
              <tr>
                <td>Mobile Wishlist</td>
                {shouldShowStarterOption && (
                  <td>
                    <IconChecked />
                  </td>
                )}
                <td>
                  <IconChecked />
                </td>
                <td>
                  <IconChecked />
                </td>
              </tr>
              <tr>
                <td>Analytics Report</td>
                {shouldShowStarterOption && (
                  <td>
                    <IconChecked />
                  </td>
                )}
                <td>
                  <IconChecked />
                </td>
                <td>
                  <IconChecked />
                </td>
              </tr>
              <tr>
                <td>Smart App Banner</td>
                {shouldShowStarterOption && (
                  <td>
                    <IconChecked />
                  </td>
                )}
                <td>
                  <IconChecked />
                </td>
                <td>
                  <IconChecked />
                </td>
              </tr>
              <tr>
                <td>Chat Support</td>
                {shouldShowStarterOption && (
                  <td>
                    <div>
                      <Icon source={IconWechat} />
                      <span className="mx-1"></span>
                      <Icon source={IconFacebook} />
                    </div>
                  </td>
                )}
                <td>
                  <div>
                    <Icon source={IconWechat} />
                    <span className="mx-1"></span>
                    <Icon source={IconFacebook} />
                  </div>
                </td>
                <td>
                  <div>
                    <Icon source={IconWechat} />
                    <span className="mx-1"></span>
                    <Icon source={IconFacebook} />
                  </div>
                </td>
              </tr>
              <tr>
                <td>Text Push Marketing</td>
                {shouldShowStarterOption && <td><IconChecked /></td>}
                <td>Unlimited</td>
                <td>Unlimited</td>
              </tr>
              <tr>
                <td>Animated lookbook Push Marketing</td>
                {shouldShowStarterOption && (
                  <td>
                    <IconX />
                  </td>
                )}
                <td>Unlimited</td>
                <td>Unlimited</td>
              </tr>
              <tr>
                <td>Scheduled Push Marketing</td>
                {shouldShowStarterOption && (
                  <td>
                    <IconX />
                  </td>
                )}
                <td>Unlimited</td>
                <td>Unlimited</td>
              </tr>
              <tr>
                <td>Deep linking</td>
                {shouldShowStarterOption && (
                  <td>
                    <IconX />
                  </td>
                )}
                <td>
                  <IconChecked />
                </td>
                <td>
                  <IconChecked />
                </td>
              </tr>
              <tr>
                <td>Order Note</td>
                {shouldShowStarterOption && (
                  <td>
                    <IconX />
                  </td>
                )}
                <td>
                  <IconChecked />
                </td>
                <td>
                  <IconChecked />
                </td>
              </tr>
              <tr>
                <td>Abandonded Cart Notification</td>
                {shouldShowStarterOption && (
                  <td>
                    <IconX />
                  </td>
                )}
                <td>
                  <IconChecked />
                </td>
                <td>
                  <IconChecked />
                </td>
              </tr>
              <tr>
                <td>Quick Add</td>
                {shouldShowStarterOption && (
                  <td>
                    <IconX />
                  </td>
                )}
                <td>
                  <IconChecked />
                </td>
                <td>
                  <IconChecked />
                </td>
              </tr>
              <tr>
                <td>AI Product Recommendation</td>
                {shouldShowStarterOption && (
                  <td>
                    <IconX />
                  </td>
                )}
                <td>
                  <IconX />
                </td>
                <td>
                  <IconChecked />
                </td>
              </tr>
              <tr>
                <td>Facebook Ad Integration</td>
                {shouldShowStarterOption && (
                  <td>
                    <IconX />
                  </td>
                )}
                <td>
                  <IconX />
                </td>
                <td>
                  <IconChecked />
                </td>
              </tr>
              <tr>
                <td>Google Analytics Integration</td>
                {shouldShowStarterOption && (
                  <td>
                    <IconX />
                  </td>
                )}
                <td>
                  <IconX />
                </td>
                <td>
                  <IconChecked />
                </td>
              </tr>
              <tr>
                <td>Judge.me</td>
                {shouldShowStarterOption && (
                  <td>
                    <IconX />
                  </td>
                )}
                <td>
                  <IconX />
                </td>
                <td>
                  <IconChecked />
                </td>
              </tr>
              <tr>
                <td>Stamped.IO</td>
                {shouldShowStarterOption && (
                  <td>
                    <IconX />
                  </td>
                )}
                <td>
                  <IconX />
                </td>
                <td>
                  <IconChecked />
                </td>
              </tr>
              <tr>
                <td>Member-Only Price Display</td>
                {shouldShowStarterOption && (
                  <td>
                    <IconX />
                  </td>
                )}
                <td>
                  <IconX />
                </td>
                <td>
                  <IconChecked />
                </td>
              </tr>
              <tr>
                <td>Dedicated Manager</td>
                {shouldShowStarterOption && (
                  <td>
                    <IconX />
                  </td>
                )}
                <td>
                  <IconX />
                </td>
                <td>
                  <IconChecked />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="pt-8 pb-16 text-center">
          <span className="font-semibold">* Please Note *</span>
          <p>
            Once the plan is approved, we start to build app immediately. All
            fees for the Services are non-refundable.
          </p>
        </div>
      </div>
    </div>
  )
}
